<template>
  <fieldset>
    <DocFieldsBaseIz
        :modelValue="data"
        :document-type-id="documentTypeId"
        :document-reg-num="documentRegNum"
        @update:modelValue="$emit('update:modelValue', $event)"
    >
      <template #additional-fields>        
      </template>
      <template #additional-fields-bottom>
        <MultiSelectField
            :modelValue="modelValue.zouitRef"
            @update:modelValue="updateVector($event, v$.zouitRef)"
            :options="vectors"
            @reload="fetchWithDelay"
            :errors="v$.$error ? v$.zouitRef.$errors : []"
            :itemValidationRule="itemValidationRule"
            data-qa="zouit"
            keyProp="externalId"
        >
          <label class="label label--required">Зона с особыми условиями использования территории</label>
          <template #add>Добавить</template>
        </MultiSelectField>
      </template>
    </DocFieldsBaseIz>
  </fieldset>
</template>

<script>
import DocSection10Iz from "../../../models/documents/docSection10Iz";
import DocFieldsBaseIz from "./DocFieldsBaseIz";
import MultiSelectField from "../../basic/MultiSelectField";
import {toRefs} from "vue";
import {helpers, required} from "@vuelidate/validators";
import useValidator from "./validator";
import { useVectorZouit } from "@/hooks/vector";
// import GisogdDocumentsProxy from "../proxies/gisogdDocumentsProxy";

export default {
  emits: ["update:modelValue", "update:vector"],
  props: {
    modelValue: {
      type: Object,
      required: true,
      default: () => new DocSection10Iz(),
    },
    documentTypeId: {
      type: Number,
      required: true,
    },
    documentRegNum: {
      type: String,
      required: false
    }
  },
  components: {DocFieldsBaseIz, MultiSelectField},
  setup(props, {emit}) {
    const {modelValue: data} = toRefs(props);
    const {vectors, fetchWithDelay} = useVectorZouit();


    const validationRules = {
      zouitRef: {
        required: helpers.withMessage("Заполните поле", required),
      }
    };

    let itemValidationRule = {
      externalId: helpers.withMessage("Заполните или удалите неиспользуемое поле", (value) => {
        return value !== null && value["externalId"] !== undefined
      })
    }

    const v$ = useValidator(data, validationRules);

    function update(fieldName, value, field) {
      const newValue = {...props.modelValue};
      newValue[fieldName] = value;
      if (field) field.$model = value;
      emit("update:modelValue", newValue);
    }

    function updateVector(value, field) {
      update("zouitRef", value, field);
      emit("update:vector", value);
    }

    return {data, update, updateVector, v$, vectors, fetchWithDelay, itemValidationRule};
  },
};
</script>